import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Creating An Environment For Appreciating Employees",
  "author": "Anthony",
  "date": "2020-03-06T08:40:32.000Z",
  "categories": ["employee appreciation", "Personal-Professional Growth", "values"],
  "slug": "creating-an-environment-for-appreciating-employees",
  "draft": false,
  "meta_title": "Creating An Environment For Appreciating Employees"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sure there are many ways to show appreciation to your employees, but at the end of the day, a strong foundation is needed for employees to feel appreciated. We all need clear guidance and a common understanding of how we work with each other and for our customers.`}</p>
    <p>{`If this foundation is established - a plethora of additional ways of showing employee appreciation is the icing on the cake.`}</p>
    <p>{`When we founded Purepost, the first task was to establish our: Vision, Purpose, Mission, Culture, and Values. Without these key ingredients, there is no way we could succeed. This foundation provides employees with an understanding of why the business exists, how we will be successful, and how we’ll treat one another.`}</p>
    <p>{`Every employee hired exemplifies Purepost’s values. We all adhere to the Values because they are a part of who we are. Our values of integrity, accountability, courage, collaboration, and innovativeness ensure that employees feel supported and valued-a natural environment for the cultivation of appreciation.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "34.6875%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/ElEQVQoz43RIUsEURTF8d/MiKBYLK7rjCuyiEnUIGLRYLEYFhTEKqwrCBaTWEx+A6tNMduMRr+BFoNlWZtZWJndN+sgKB64vPfuhf99nONdqiPzakpXXcsYxBGRvqJQMZJQ8Y/3twrgm1TXrGYfOKD9U6OY6N1yWEe22pFefcgaYbgaMYNxLGIJa2jiABvYxhZOcY5KGXjRlj5/ql1mkhXsRBzjCGcBdIhb3OMB1+F8xEn4aVIA19vSm67aLuawH1HHJuZRRb7oDi3UsIyF0K+XPOwBPZmMG0by1nBFnPziVQ4Z+tPNAviiajoEttezcZBkVKpCcSn5uDz7AkAYL6SyQCpzAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "purepost logo redblack",
        "title": "purepost logo redblack",
        "src": "/static/10a05829b4b71211d8b7d0a383f00dd4/21b4d/purepost-logo-redblack.png",
        "srcSet": ["/static/10a05829b4b71211d8b7d0a383f00dd4/72799/purepost-logo-redblack.png 320w", "/static/10a05829b4b71211d8b7d0a383f00dd4/6af66/purepost-logo-redblack.png 640w", "/static/10a05829b4b71211d8b7d0a383f00dd4/21b4d/purepost-logo-redblack.png 1280w", "/static/10a05829b4b71211d8b7d0a383f00dd4/29114/purepost-logo-redblack.png 1920w", "/static/10a05829b4b71211d8b7d0a383f00dd4/c2d13/purepost-logo-redblack.png 2560w", "/static/10a05829b4b71211d8b7d0a383f00dd4/6a878/purepost-logo-redblack.png 2846w"],
        "sizes": "(max-width: 1280px) 100vw, 1280px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Our Purpose`}</h3>
    <p>{`To Provide First-Class Solutions for Veterans`}</p>
    <h3>{`Our Mission`}</h3>
    <p>{`Transforming Veterans Self-Worth and Value for Optimal Fit in Civilian Business and Educational Organizations`}</p>
    <h3>{`Our Culture`}</h3>
    <p>{`The Purepost Culture is a seamless combination of a High Performing Team Culture (An organization that focuses on internal maintenance with flexibility, concern for team members, and high sensitivity to customers); and a `}<strong parentName="p">{`Market/Mission Culture`}</strong>{` (An organization that focuses on external positioning with a need for stability and control to serve specific customers).`}</p>
    <h3>{`Core Values`}</h3>
    <h5><em parentName="h5">{`Purepost Team Members Will Embody Integrity`}</em></h5>
    <p>{`We will act with honor and uncompromising honesty in everything we do. We will never mislead and always do what we say and say what we mean.`}</p>
    <h5><em parentName="h5">{`Purepost Team Members Will Embody Accountability`}</em></h5>
    <p>{`We will follow through on all of our promises and commitments to others. We will take full ownership of our work and accept responsibility for our failures and mistakes. We will earn and measure our success through the collective achievement of business objectives.`}</p>
    <h5><em parentName="h5">{`Purepost Team Members Will Embody Courage`}</em></h5>
    <p>{`We will take a stand on issues and speak our mind, and never be deterred by fear and uncomfortable topics. We will make tough yes/no decisions in a timely manner and with confidence.`}</p>
    <h5><em parentName="h5">{`Purepost Team Members Will Embody Collaboration`}</em></h5>
    <p>{`We will value the importance of working with others, sharing information freely and building a culture of openness, candor, accountability, and trust. We will listen attentively to others; ask questions for clarification, and work to see things through the eyes of others.`}</p>
    <h5><em parentName="h5">{`A Purepost Team Member Will Embody Innovativeness`}</em></h5>
    <p>{`We will act with curiosity and creativity and stay abreast of leading-edge technology and best practices. We will always ask "why", a minimum of five times in order to identify opportunities, take a prudent risk and challenge the status quo.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      